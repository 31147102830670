export default [{
  path: '/login',
  name: 'HrLogin',
  hidden: true,
  component: function component(resolve) {
    return require(['@/viewshr/login'], resolve);
  }
}, {
  path: '/',
  component: function component(resolve) {
    return require(['@/viewshr/home'], resolve);
  },
  hidden: true,
  children: [{
    path: 'dashboard',
    name: 'HrDashboard',
    component: function component(resolve) {
      return require(['@/viewshr/dashboard/Dashboard'], resolve);
    },
    meta: {
      title: '洞察'
    }
  }, {
    path: 'project/list',
    name: 'HrProjectList',
    component: function component(resolve) {
      return require(['@/viewshr/project/ProjectList'], resolve);
    },
    meta: {
      title: '项目'
    }
  }, {
    path: 'project/info',
    name: 'HrProjectDetail',
    component: function component(resolve) {
      return require(['@/viewshr/project/ProjectDetail.vue'], resolve);
    },
    meta: {
      title: '项目详情'
    }
  }, {
    path: 'userinfo',
    name: 'HrUserInfo',
    component: function component(resolve) {
      return require(['@/viewshr/user/UserInfo'], resolve);
    },
    meta: {
      title: '账号'
    }
  }, {
    path: 'coachee/list',
    name: 'coacheeList',
    component: function component(resolve) {
      return require(['@/viewshr/coachee/CoacheeList.vue'], resolve);
    },
    meta: {
      title: '学员'
    }
  }, {
    path: 'coachee/detail',
    name: 'CoacheeDetail',
    component: function component(resolve) {
      return require(['@/viewshr/coachee/CoacheeDetail'], resolve);
    },
    meta: {
      title: '学员详情'
    }
  }, {
    path: 'coach/list',
    name: 'HrCoachList',
    component: function component(resolve) {
      return require(['@/viewshr/coach/CoachList'], resolve);
    },
    meta: {
      title: '教练库'
    }
  }, {
    path: 'coach/detail',
    name: 'HrCoachDetail',
    component: function component(resolve) {
      return require(['@/viewshr/coach/CoachDetail'], resolve);
    },
    meta: {
      title: '教练详情'
    }
  }]
}];