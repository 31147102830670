var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("svg-icon", {
    attrs: {
      "icon-class": "doc"
    },
    on: {
      click: _vm.click
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };