var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("iframe", {
    staticStyle: {
      width: "880px",
      height: "490px"
    },
    attrs: {
      src: _vm.IMURL,
      frameborder: "0",
      allow: "microphone;camera;midi;encrypted-media;"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };