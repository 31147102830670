import { getToken } from "@/utils/auth";
export default {
  name: "service",
  data: function data() {
    return {
      IMURL: ""
    };
  },
  created: function created() {
    this.IMURL = "".concat(process.env.VUE_APP_IM_URL, "/h5/IM?token=").concat(getToken());
  },
  methods: {}
};