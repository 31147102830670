var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "app-main"
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("keep-alive", {
    attrs: {
      include: _vm.cachedViews
    }
  }, [_c("router-view", {
    key: _vm.key
  })], 1)], 1), _vm._v(" "), _c("el-backtop", {
    attrs: {
      bottom: 50,
      right: 40
    }
  }, [_c("i", {
    staticClass: "el-icon-caret-top"
  })]), _vm._v(" "), _vm.$store.state.settings.showFooter ? _c("div", {
    attrs: {
      id: "el-main-footer"
    }
  }, [_c("span", {
    domProps: {
      innerHTML: _vm._s(_vm.$store.state.settings.footerTxt)
    }
  }), _vm._v(" "), _vm.$store.state.settings.caseNumber ? _c("span", [_vm._v(" ⋅ ")]) : _vm._e(), _vm._v(" "), _c("a", {
    attrs: {
      href: "https://beian.miit.gov.cn/#/Integrated/index",
      target: "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$store.state.settings.caseNumber))])]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };