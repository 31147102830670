import "core-js/modules/es6.regexp.to-string";
import axios from "axios";
import router from "@/router/routers";
import { Notification } from "element-ui";
import store from "../store";
import { getToken } from "@/utils/auth";
import Config from "@/settings";
import Cookies from "js-cookie";

// 创建axios实例
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  timeout: Config.timeout // 请求超时时间
});

// request拦截器
service.interceptors.request.use(function (config) {
  if (getToken()) {
    config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers["Content-Type"] = "application/json";
  return config;
}, function (error) {
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  // 兼容blob下载出错json提示
  if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf("json") !== -1) {
    var reader = new FileReader();
    reader.readAsText(error.response.data, "utf-8");
    reader.onload = function (e) {
      var errorMsg = JSON.parse(reader.result).message;
      Notification.error({
        title: errorMsg,
        duration: 5000
      });
    };
  } else {
    var code = 0;
    try {
      code = error.response.data.status;
    } catch (e) {
      if (error.toString().indexOf("Error: timeout") !== -1) {
        Notification.error({
          title: "网络请求超时",
          duration: 5000
        });
        return Promise.reject(error);
      }
    }
    console.log(code);
    if (code) {
      if (code === 401) {
        store.dispatch("LogOut").then(function () {
          // 用户登录界面提示
          Cookies.set("point", 401);
          location.reload();
        });
      } else if (code === 403) {
        router.push({
          path: "/401"
        });
      } else {
        var errorMsg = error.response.data.message;
        if (errorMsg !== undefined) {
          Notification.error({
            title: errorMsg,
            duration: 5000
          });
        }
      }
    } else {
      Notification.error({
        title: "接口请求失败",
        duration: 5000
      });
    }
  }
  return Promise.reject(error);
});
export default service;