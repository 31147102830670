import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/workspace/web-admin_tluP/node_modules/_@babel_runtime-corejs2@7.24.5@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import router from "./routers";
import store from '@/store';
import Config from '@/settings';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken } from '@/utils/auth'; // getToken from cookie
import { buildMenus } from '@/api/system/menu';
import { filterAsyncRouter } from '@/store/modules/permission';
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

var whiteList = ['/login']; // no redirect whitelist

router.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + Config.title;
  }
  NProgress.start();
  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({
        path: '/'
      });
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(function () {
          // 拉取user_info
          // 动态路由，拉取菜单
          loadMenus(next, to);
        }).catch(function () {
          store.dispatch('LogOut').then(function () {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
        // 登录时未拉取 菜单，在此处拉取
      } else if (store.getters.loadMenus) {
        // 修改成false，防止死循环
        store.dispatch('updateLoadMenus');
        loadMenus(next, to);
      } else {
        next();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      if (to.path.indexOf('/hr') > -1 && to.path.indexOf('/hr/login') > -1) {
        next(); // 带有hr关键字的路由，去登录页的直接跳转
      } else if (to.path.indexOf('/hr') > -1 && to.path.indexOf('/hr/login') <= -1) {
        next("/hr/login?redirect=".concat(to.fullPath)); // 带hr关键字的路由未登录的重定向到hr系统登录页
        NProgress.done();
      } else {
        next("/login?redirect=".concat(to.fullPath)); // 否则全部重定向到登录页
        NProgress.done();
      }
    }
  }
});
export var loadMenus = function loadMenus(next, to) {
  buildMenus().then(function (res) {
    var sdata = JSON.parse(JSON.stringify(res));
    var rdata = JSON.parse(JSON.stringify(res));
    var sidebarRoutes = filterAsyncRouter(sdata);
    var rewriteRoutes = filterAsyncRouter(rdata, false, true);
    rewriteRoutes.push({
      path: '*',
      redirect: '/404',
      hidden: true
    });
    store.dispatch('GenerateRoutes', rewriteRoutes).then(function () {
      // 存储路由
      router.addRoutes(rewriteRoutes); // 动态添加可访问路由表
      next(_objectSpread(_objectSpread({}, to), {}, {
        replace: true
      }));
    });
    store.dispatch('SetSidebarRouters', sidebarRoutes);
  });
};
router.afterEach(function () {
  NProgress.done(); // finish progress bar
});