import "/root/workspace/web-admin_tluP/node_modules/_core-js@2.6.12@core-js/modules/es6.array.iterator.js";
import "/root/workspace/web-admin_tluP/node_modules/_core-js@2.6.12@core-js/modules/es6.promise.js";
import "/root/workspace/web-admin_tluP/node_modules/_core-js@2.6.12@core-js/modules/es6.object.assign.js";
import "/root/workspace/web-admin_tluP/node_modules/_core-js@2.6.12@core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalizecss/normalize.css';
import Element from 'element-ui';

// 数据字典
import dict from "./components/Dict";

// 权限指令
import checkPer from '@/utils/permission';
import permission from "./components/Permission";
import "./assets/styles/element-variables.scss";

// global css
import "./assets/styles/index.scss";
import App from "./App";
import store from "./store";
import router from "./router/routers";
import "./assets/icons"; // icon
import "./router/index"; // permission control

import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';

//日期处理
import dayjs from "dayjs";
Vue.prototype.$dayjs = dayjs; //挂在到vue的原型对象上，这样可在全局中使用

Vue.use(Tooltip);
import layer from 'vue-pubilc-layer';
Vue.use(layer);
import VueParticles from 'vue-particles';
Vue.use(VueParticles);
import VueClipBoard from 'vue-clipboard2';
Vue.use(VueClipBoard);
Vue.use(checkPer);
Vue.use(permission);
Vue.use(dict);
Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});