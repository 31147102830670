import request from "@/utils/request";
// 获取教练列表
export function appinstructorPage(data) {
  return request({
    url: "/appinstructor/list",
    method: "post",
    data: data
  });
}
// 获取教练详情
export function appinstructorInfo(params) {
  return request({
    url: "/appinstructor/info",
    method: "get",
    params: params
  });
}
// 新增教练
export function appinstructorAdd(data) {
  return request({
    url: "/appinstructor/save",
    method: "post",
    data: data
  });
}
export function appinstructorShare(params) {
  return request({
    url: "/appinstructor/shareId",
    method: "get",
    params: params
  });
}
// 编辑教练
export function appinstructorUpdate(data) {
  return request({
    url: "/appinstructor/update",
    method: "post",
    data: data
  });
}
// 删除教练
export function appinstructorRemove(params) {
  return request({
    url: "/appinstructor/remove",
    method: "get",
    params: params
  });
}
// 获取领域列表
export function appdomainPage(data) {
  return request({
    url: "/appdomain/page",
    method: "post",
    data: data
  });
}

// 启用/禁用教练
export function appinstructorEnable(data) {
  return request({
    url: "/appinstructor/enable",
    method: "post",
    data: data
  });
}
// 客服获取消息未读数量
export function getUnReadCount() {
  return request({
    url: "/appinstructor/getUnReadCount",
    method: "get"
  });
}